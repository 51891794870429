<!-- 客户面板 -->
<template>
  <div>
    <blockTitle title="客户面板"></blockTitle>

    <el-card shadow="never">
      <div class="card-content flex flex-row">
        <div
            class="mr-4 border w-96 p-4 leading-9"
            v-for="(item, index) in sysPanelList"
            :key="index"
        >
          <div class="">
            <img class="h-32 object-cover" :src="item.coverImg"/>
          </div>
          <div class="panel-name font-bold">
            {{ item.panelName }}
          </div>
          <div class="panel-intro text-sm">功能介绍：{{ item.boardIntro }}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      sysPanelList: []
    }
  },
  methods: {
    getSysPanel () {
      this.$api.findAll('sysPanel').then(res => {
        this.sysPanelList = res.data
      })
    }
  },
  mounted () {
    this.getSysPanel()
  }
}
</script>
